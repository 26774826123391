.faq-question-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-question.open .faq-question-answer {
    max-height: 120px; /* Puedes cambiar este valor según el tamaño de tu contenido */
  }
  
  .faq-question-title {
    cursor: pointer;
        font-family: 'Roboto', sans-serif;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 2rem;


  }
  