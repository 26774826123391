.title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    color: #333;
  }
  
  .title-text {
    font-family: 'Roboto', sans-serif; /* You can replace Roboto with any Google font you like */
    font-size: 2.5rem;
    text-align: center;
    max-width: 80%;
    margin-bottom: 2rem;
    line-height: 1.8;
  }
  
  .highlight {
    background-color: #ffe600;
    padding: 0.5rem;
    border-radius: 5px;
  }
  
  .whatsapp-button {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #25D366;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-button:hover {
    background-color: #128C7E;
  }

  .highlight {
    position: relative;
    background-color: #ffe600;
    padding: 0.5rem;
    border-radius: 5px;
    opacity: 0;
    animation: filosofoFadeIn 0.5s forwards;
  }
  
  @keyframes filosofoFadeIn {
    0% {
      opacity: 0;
      top: 20px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  .info-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    text-align: center;
    max-width: 80%;
    margin-top: 2rem;
    color: #555;
  }

  /* ... rest of your CSS ... */

  .app-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 1rem;
  }
  
  